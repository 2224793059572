import axiosInstance from './axios/axiosConfig'
import { setPreparedAxiosParams } from './api'

export const apiParser = {
  /**
   *
   * @param {{
   *     page?: number,
   *     page_size?: number,
   *     account?: number,
   *     is_parsed?: boolean,
   *     id?: number|string
   * }} params
   * @return {Promise<{
   *     count: number,
   *     next: number|null,
   *     previous: number|null,
   *     results: {
   *         id: number,
   *         files: number[],
   *         project: number,
   *         marks: number[],
   *         template: number
   *     }[]
   * }>}
   */
  getDocx: params => axiosInstance(`/parsers/docx/`, { params }),

  postToDocxParser: data => axiosInstance.post(`/parsers/docx/`, data),

  postToReportParser: data => axiosInstance.post(`/parsers/report/`, data),

  postToPptxParser: data => axiosInstance.post(`/parsers/pptx/`, data),


  getTelegramChannelList: (page, page_size, ordering, search) =>
    axiosInstance(
      '/parsers/telegram/',
      setPreparedAxiosParams({ page, page_size, ordering, search }),
    ),

  createTelegramChannel: data => axiosInstance.post('/parsers/telegram/', data),

  getOneTelegramChannel: id => axiosInstance(`/parsers/telegram/${id}/`),

  editOneTelegramChannel: (id, data) => axiosInstance.patch(`/parsers/telegram/${id}/`, data),

  deleteOneTelegramChannel: id => axiosInstance.delete(`/parsers/telegram/${id}/`),

  createTelegramArticleByParser: data => axiosInstance('/telegram/article/', data),

  getFontColors: () => axiosInstance('/parsers/font-color/'),

  getFontSizes: () => axiosInstance('/parsers/font-size/'),

  getFontNames: () => axiosInstance('/parsers/font-name/'),

  createDocxReport: data => axiosInstance.post('/articles/export/', data),

  /**
   *
   * @param id
   */
  deleteOneDocxReport: id => axiosInstance.delete(`/parsers/docx/${id}/`),

  /**
   * @typedef {{
   *     id: number,
   *     files: number[],
   *     project: number,
   *     marks: number[],
   *     template: number,
   *     is_parsed: boolean,
   *     error: string
   * }} ParserXlsx
   */

  /**
   * @description Get xlsx parsers
   * @param {{
   *     [page]: number,
   *     [page_size]: number
   *     [is_parsed]: boolean
   *     [id]: number|string
   * }} params
   * @return {Promise<{
   * count: number,
   * next: number|null,
   * previous: number|null,
   * results: ParserXlsx[]
   * }>}
   */
  getXlsxParsersList: params => axiosInstance('/parsers/xlsx/', { params }),

  /**
   * @description Parse xlsx
   * @param {
   *     files: number[],
   *     project: number,
   *     marks: number[],
   *     template: number
   * } data
   * @return {Promise<ParserXlsx>}
   */

  postToXlsxParser: data => axiosInstance.post('parsers/xlsx/', data),

  /**
   *
   * @param {number} id
   * @return {ParserXlsx}
   */
  getOneXlsxParser: id => axiosInstance(`/parsers/xlsx/${id}/`),

  /**
   *
   * @param {number} id
   * @return {Promise<ParserXlsx>}
   */
  restartOneXlsxParser: id =>
    axiosInstance.patch(`/parsers/xlsx/${id}/`, {
      is_parsed: true,
      error: '',
    }),

  /**
   *
   * @param {number} id
   * @return {Promise<void>}
   */
  deleteOneXlsxReport: id => axiosInstance.delete(`/parsers/xlsx/${id}/`),

  /**
   * @typedef {{
   *     page: number,
   *     page_size: number,
   *     account: number,
   *     datetime_parsed__gte: string,
   *     datetime_parsed__lte: string,
   *     ordering: 'id' | 'project__title' | 'template' | 'account__last_name' | 'datetime_parsed'
   * }} StatisticsParams
   */

  /**
   * @param {StatisticsParams} params
   * @return {Promise<{
   *     count: number,
   *     next: number|null,
   *     previous: number|null,
   *     results: {
   *         id: number,
   *         files: {
   *             id: number,
   *             title: string
   *         }[],
   *         project: {
   *             id: number,
   *             title: string
   *         },
   *         marks: {
   *             id: number,
   *             title: string
   *         }[],
   *         template: number,
   *         account: {
   *             id: number,
   *             first_name: string,
   *             middle_name: string,
   *             last_name: string
   *         },
   *         datetime_parsed: string
   *     }[]
   * }>}
   */
  getXlsxParserStatistics: params => axiosInstance('/parsers/xlsx/stat/', { params }),

  /**
   *
   * @param {StatisticsParams} params
   * @return {Promise<AxiosResponse<any>> | *}
   */
  getDocxParserStatistic: params => axiosInstance('/parsers/docx/stat/', { params }),



}
